import { convertToDefaultDateHourFormat } from "@/util"

export const SENT_EMAIL_ARG = {
  LABEL: 'label',
  DATETIME: 'datetime',
  IS_ARCHIVED: 'is_archived'
}

export default class SentEmailModel {
  constructor(item = {}) {
    this.label = item[SENT_EMAIL_ARG.LABEL]
    this._datetime = item[SENT_EMAIL_ARG.DATETIME]
    this.isArchived = item[SENT_EMAIL_ARG.IS_ARCHIVED]
  }

  get datetime() {
    return convertToDefaultDateHourFormat(this._datetime)
  }
}
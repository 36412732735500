<template lang="pug">
  div(
    :value="value"
    color="drawer"
    :dark="dark"
    fixed
    right
    :width="width"
    ref='drawer'
  ).logs
    slot(name="header" :model="model" :loadData="loadData" :getDefaultPaginationParams="getDefaultPaginationParams")
    .d-flex.align-center.fill-height.justify-center(v-if="model && model.items && !model.items.length && loading")
      v-progress-circular(indeterminate size=64 color="warning")
    infinity-scroll(
      :hasNextPage="isNeedToLoadMore"
      @scrolling="onLoadMoreClick"
      :defaultHeight="height"
      :loading="loadingMore"
      :resetScroll="resetScroll"
      data-test='infintiy-scroll'
    )
      v-list(v-if="model && model.items && model.items.length" dark)
        v-list-item(v-for="(item, index) in model.items" :key="index" :class="['log_' + item.status]" data-test="log-item").log
          v-list-item-content.log__content
            div(v-if="item.candidate && item.candidate.name").log__item
              b {{ item.createdDate }}&nbsp;
              slot(:candidate="item.candidate" :fullName="item.fullName" name="candidateName")
                span.link {{ item.fullName }}
                span.link  - {{ item.candidate.number }},
              br(v-if="item.author")
              span.log__item-author( v-if="item.author" ) {{item.author.email || item.author.username}} :
              span.log__item-comment {{ item.comment }}
            div(v-else)
              b {{ item.createdDate }}
              br( v-if="item.author" )
              span.log__item-author( v-if="item.author" ) {{item.author.email || item.author.username}} :
              span.log__item-comment {{ item.comment }}
      p.text-center(v-else-if="!loading" data-test='log-not-found').log__not-found.d-flex.align-center.fill-height.justify-center LOGS NOT FOUND
</template>

<script>

import ErrMixin from "@/mixins/errors.mixin"
import PaginationModel from "@/models/PaginationOffsetModel"
import {DEFAULT_LIMIT_PAGINATION} from "@/util/const"
export default {
  name: "Logs",
  mixins: [ErrMixin],

  props: {
    dark: {
      default: true,
      type: Boolean
    },
    model: {
      required: true
    },
    value: Boolean,
    width: {
      type: String,
      default: '350px'
    },
    height: {
      type: String,
      default: '100vh'
    }
  },
  data: () => ({
    pagination: null,
    loading: false,
    loadingMore: false,
    fullList: false,
    resetScroll :false
  }),

  computed: {
    isNeedToLoadMore() {
      if (!this.model || !this.model.items || !this.model.items.length) return false
      if (this.fullList) return false
      if (this.loadingMore) return false
      return true
    }
  },

  methods: {
    onLoadMoreClick() {
      this.$emit('click:loadMore')

    },
    async loadMore(params) {
      if (!this.pagination || !this.pagination.next) {
        this.fullList = true
        return
      }
      this.pagination.loadMore()
      let _params = {...params,...this.getPaginationParams()}
      try {
        this.loadingMore = true
        let {results, next} = await this.model.list(_params, true)
        if(!next) this.fullList = true
        this.loadingMore = false
        return results
      } catch (error) {
        console.log(error)
        this.loadingMore = false

        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    async loadData(params, more = false) {
      try {
        this.loading = true
        let {results, ...paginationParams} = await this.model.list(params, more)
        this.pagination = new PaginationModel({...paginationParams, params})
        this.loading = false
      } catch (error) {
        console.log(error)
        this.loading = false
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      }
    },

    getPaginationParams() {
      return {limit: this.pagination.limit, offset: this.pagination.offset}
    },

    getDefaultPaginationParams() {
      return {limit: DEFAULT_LIMIT_PAGINATION.LIMIT, offset: DEFAULT_LIMIT_PAGINATION.OFFSET}
    },
  },

  watch: {
    value(value) {
      this.resetScroll = !value;
    }
  },

  components: {
    infinityScroll: () => import('@/components/global/infinityScroll.vue')
  }
}
</script>

<style lang="scss" scoped>
.log {
  position: relative;
  margin-top: 14px;
  margin-right: 13px;
  margin-left: 13px;
  border-radius: 3px;
  background-color: $log-primary;
  user-select: initial !important;

  &__item {
    &-comment {
      display: flex;
      word-break: break-all;
    }
  }

  &.theme--dark.v-list-item {
    color: $log-default !important;
  }

  &::before {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 0;
    border-top-left-radius: 3px;
    border-bottom-left-radius: 3px;
    content: "";
  }
  &_success {
    &.theme--dark.v-list-item {
      color: #FFFFFF !important;
    }
    &::before {
      width: 3px;
      background-color: #36C175;
    }
  }
  &_error {
    &.theme--dark.v-list-item {
      color: #FFFFFF !important;
    }
    &::before {
      width: 3px;
      background-color: $error-color;
    }
  }
  &__content::v-deep {
    font-weight: 500;
    font-size: 12px;
    line-height: 1.5;
  }
  &__item {
    line-height: 1.5;
    &-comment {
      white-space: pre-wrap;
    }
    &-author {
      color: #FFFFFF;
      font-weight: bolder;
    }
  }
  &__not-found {
    color: #FFFFFF;
    text-align: center;
    text-transform: uppercase;
    font-size: 20px;
  }
}

.logs {
  background-color: $log-secondary !important;
}

</style>

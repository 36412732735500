<template lang="pug">
  .filters-wrapper#candidates-filters(:class="{'filters-wrapper_active': showing}" data-test="candidates-filters")
    portal(to="candidate-location-menu")
      sidebar-item(@click="goToLocation()" text="all" data-test="c-menu-all" :class="{'sub-item_active': isAllPage}")
      sidebar-item(
        v-for="location in this.crmCourseLocation" :key="location.ID"
        :class="{'sub-item_active': isCandidatesLocationActive(location.ID)}"
        :text="location.name"
        data-test="c-menu-location"
        @click="goToLocation(location.ID)"
        link
      )
    portal(to="candidates-list-top")
      live-search-provider(#default="liveSearch")
        .filters-candidate
          live-search(
            data-test="candidate-search"
            :list="liveSearch.list"
            :loading="liveSearch.loading"
            @onFocusToggle="liveSearch.updateFocused"
            @searching="liveSearch.search"
            v-model="filtersModel.filters.search"
            @enter-key-pressed="liveSearchUpdate"
            @change="liveSearchUpdate"
            placeholder='Search by CBR account, client name, data etc…'
          ).search
            template(v-slot:live-search="liveSearchItems")
              live-search-item(
                v-for="item in liveSearch.list"
                :key="item.id"
                :item="item"
                @click.native.stop="onLiveSearchClick(item, liveSearchItems.select)"
              )
          div(v-show="!liveSearch.isFocused").filters-candidate__main
            portal(to="candidate-labels-list")
              .candidates-labels-list
                div(v-for="(labelSection, index) in everyLabels" :key="`${index}${$route.name}`").d-flex
                  div(v-for="(item, index) in labelSection").mr-2.mt-1.mb-1
                    app-label(
                      v-if="labelsMap[item]"
                      :keyValue="item"
                      :label="labelsMap[item].label"
                      :isBtn="false"
                      :color="labelsMap[item].color"
                    )
                      template(v-slot:prepend="labelproprs" v-if="labelsMap[item].exclude")
                        v-icon(x-small color="white").mr-1.block mdi-alert-outline
            .candidates-labels
              labels(
                v-if="labelItems"
                ref="labels"
                :items="labelItems"
                itemValue="key"
                :value="labels"
                @changed="setLabels"
                @changed:exclude="setLabelsExclude"
              )
              customLabels(v-if="selectedCustomLabels" :value="selectedCustomLabels" @changed="setCustomLabels" @changed:exclude="setCustomLabelsExclude")
            v-btn(:outlined="showing" color="primary" data-test="candidates-filters-btn" @click="toggleFilters").text-capitalize.btn-commmon_bold
              v-icon(left v-if="!showing") mdi-cog
              v-icon(left v-if='showing') mdi-close
              | filters

    portal(to="candidates-filters-chips")
      filters-chips(:model="filtersModel" @remove="removeFilter" @remove:multiple="removeFilterMultiple")

    portal(to="candidates-quick-filters")
      quickFilters(@click:quickFilter="setQuickFilters" @click:clearQuickFilter="clearQuickFilters")

    candidates-list-filters(
      :filtersModel="filtersModel.filters"
      @changeMultiple="setAdditionalMultipleFilters"
      @change="setAdditionalFilter"
      @changeDialogFilters="applyTemporaryFilters"
    )

    candidates-sidebar-filters(
      @applyFilters="applyTemporaryFilters"
      @click:toggle="toggleFilters"
      :filtersModel="filtersModel"
      :showing="showing"
    )
</template>

<script>
import { mapGetters } from "vuex"
import FiltersModel from "../core/models/candidatesFiltersModel"
import { LABELS, FILTERS_ARGS, CANDIDATES_TABS, QUICK_FILTER_ID, SYSTEM_LABELS } from '../core/candidates-const'
import CandidatesCount from '../core/models/candidatesCountModel'
import LabelService from "@/app/admin/models/LabelService"
import featureFlagsMixin from '@/mixins/featureFlags.mixin'

export default {
  name: 'candidatesFilters',

  props: {
    showing: {type: Boolean, default: false},
    candidatesCount: {type: null}
  },

  mixins: [featureFlagsMixin],

  data: () => ({
    loading: false,
    filtersModel: {},
    QUICK_FILTER_ID,
    labelItems: SYSTEM_LABELS
  }),

  inject: ['getDefaultPagination'],

  computed: {
    ...mapGetters({
      crmCourseLocation: 'crmCourseCities/items',
      customLabels: 'labels/items'
    }),

    labelsMap() {
      let items = {}
      this.labelItems.forEach(item => {
        items[item.key] = {
          label: item.label,
          color: item.color,
          exclude: this.filtersModel.labels.length ? false : true
        }
      })
      if (!this.customLabels) return items
      this.customLabels.forEach(item => {
        items[item.ID] = {
          label: item.name,
          color: item.color,
          exclude: this.filtersModel.customLabels.length ? false : true
        }
      })
      return items
    },

    everyLabels() {
      let labels = []
      let everyLabels = [...this.labels, ...this.selectedCustomLabels]
      everyLabels.forEach((label, index) => {
        if (index % 2 == 0) {
          labels.push([label])
          return
        }
        labels[labels.length - 1].push(label)
      })
      return labels.reverse()
    },

    labels() {
      if (this.filtersModel.labelsExclude.length) return this.filtersModel.labelsExclude
      return this.filtersModel.labels
    },

    selectedCustomLabels() {
      if (this.filtersModel.customLabelsExclude.length) return this.filtersModel.customLabelsExclude
      return this.filtersModel.customLabels
    },

    isAllPage() {
      return CANDIDATES_TABS.includes(this.$route.name) && !this.filtersModel.filters.courseCity.length
    }
  },

  created() {
    this.initFilters()
  },

  methods: {
    // Filters Panel
    toggleFilters() {
      if (!this.showing) this.loadOptions()
      this.$emit('update:showing', !this.showing)
    },

    hideFilters() {
      this.$emit('update:showing', false)
    },

    async applyTemporaryFilters(filters) {
      await this.setFilters(filters)
      this.hideFilters()
    },

    async setFilters(filters) {
      this.filtersModel.filters = {...this.filtersModel.filters, ...filters}
      await this.setQuery({[this.QUICK_FILTER_ID]: undefined})
    },

    async resetFilters() {
      let _location = this.filtersModel.filters.courseCity
      let _search = this.filtersModel.filters.search
      //
      await this.filtersModel.reset()
      //
      this.filtersModel.filters.courseCity = _location
      this.filtersModel.filters.search = _search

      setTimeout(() => {
        this.$refs.labels && this.$refs.labels.setLabels()
      }, 1000)
      await this.setFilters()
    },

    initFilters() {
      this.filtersModel = new FiltersModel(this.$route.query)
    },

    async loadOptions() {
      await this.filtersModel.loadFilterOptions(this.$store)
    },

    async setQuery(data) {
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ...this.filtersModel.getApiData(), ...data}})
      this.$emit('filterChanged')
    },

    async setPage(routeName, data) {
      await this.$router.push({
        name: routeName,
        query: {
          ...this.$route.query,
          ...this.getDefaultPagination(),
          ...this.filtersModel.getApiData(),
          ...data
        }
      })
      this.$emit('filterChanged')
    },

    // Live Search
    async liveSearchUpdate(value, hideLiveSearch) {
      if (this.$route.query.search == value) return
      try {
        hideLiveSearch && hideLiveSearch();
        if (value) await this.setQuery({search: value})
        else await this.setQuery({search: undefined})
      } catch (e) {
        console.log('error:', e)
      }
    },

    async onLiveSearchClick(item, select) {
      let toRouteName = this.getItemRouteName(item)
      if (item.is_crm) {
        select(item.id)
        this.filtersModel.filters = { ...this.filtersModel.filters, id: item.id, search: undefined }
        await this.setPage(toRouteName, {id: item.id, search: undefined})
      } else {
        select(item.application_id)
        await this.$router.push({name: toRouteName, query: {search: item.application_id}})
      }
    },

    getItemRouteName(item) {
      if (item.is_crm) return this.$ROUTER_NAMES.CANDIDATES_ALL
      else return this.$ROUTER_NAMES.TASK_APPLICATIONS
    },

    // Left Sidebar
    goToLocation(location) {
      this.filtersModel.filters.courseCity = location ? [location] : []
      this.setQuery()
    },

    isCandidatesLocationActive(ID) {
      if (!(CANDIDATES_TABS.includes(this.$route.name))) return false
      if (!this.$route.query.city) return false

      try {
        if (this.$route.query.city.some(city => city == ID)) return true
      } catch (err) {
        if (this.$route.query.city == ID) return true
      }

      return false
    },

    // Quick Filters
    async setQuickFilters({query, id}) {
      // let cityID = this.$route.query[FILTERS_ARGS.CITY_COURSE]
      await this.filtersModel.reset()
      if (this.$route.query[this.QUICK_FILTER_ID] === id) return true;
      // setup quick filter to candidates filter model
      // if (cityID) this.filtersModel.filters.courseCity = cityID
      this.filtersModel.filters = {...this.filtersModel.filters, ...query};
      await this.setQuery({[this.QUICK_FILTER_ID]: id})
    },

    async clearQuickFilters() {
      let cityID = this.$route.query[FILTERS_ARGS.CITY_COURSE]
      await this.filtersModel.reset()
      // save cityID when filters clearing
      if (cityID) this.filtersModel.filters.courseCity = cityID
      await this.setQuery({[this.QUICK_FILTER_ID]: undefined})
    },

    // Chips
    removeFilterMultiple(field) {
      this.filtersModel.filters[field] = []
      this.setQuery()
    },

    removeFilter(field) {
      this.filtersModel.filters[field] = undefined
      if (this.$route.query[this.QUICK_FILTER_ID] !== '' && this.$route.query[this.QUICK_FILTER_ID] !== undefined ) this.clearQuickFilters()
      else this.setQuery()
    },

    // Labels
    setLabels(labels) {
      this.filtersModel.labels = [...labels]
      this.filtersModel.labelsExclude = []
      this.setQuery()
    },

    setLabelsExclude(labels) {
      this.filtersModel.labels = []
      this.filtersModel.labelsExclude = [...labels]
      this.setQuery()
    },

    setCustomLabels(labels) {
      this.filtersModel.customLabels = [...labels]
      this.filtersModel.customLabelsExclude = []
      this.setQuery()
    },

    setCustomLabelsExclude(labels) {
      this.filtersModel.customLabels = []
      this.filtersModel.customLabelsExclude = [...labels]
      this.setQuery()
    },

    // Table Head Additional Filters
    setAdditionalFilter({field, value}) {
      this.filtersModel.filters[field] = value
      this.setQuery({[this.QUICK_FILTER_ID]: undefined})
    },

    setAdditionalMultipleFilters({field, value}) {
      if (this.filtersModel.filters[field].includes(value)) return
      this.filtersModel.filters[field].push(value)
      this.setQuery({[this.QUICK_FILTER_ID]: undefined})
    },
  },

  components: {
    liveSearch: () => import('@/components/global/LiveSearch/LiveSearch.vue'),
    liveSearchItem: () => import('@/components/global/LiveSearch/LiveSearchItem.vue'),
    liveSearchProvider: () => import('@/app/admin/components/LiveSearchProvider.vue'),
    search: () => import('@/components/global/Search.vue'),
    filtersChips: () => import('./filters/CandidateFiltersChips.vue'),
    labels: () => import('./candidates/CandidatesLabels.vue'),
    candidatesListFilters: () => import('./filters/CandidatesListFilters.vue'),
    selectMenu: () => import('./filters/CandidatesSelectMenu.vue'),
    labelCreate: () => import('@/app/admin/components/LabelCreate.vue'),
    appLabel: () => import('@/components/global/Label.vue'),
    customLabels: () => import('./CandidatesCustomLabels.vue'),
    sidebarItem: () => import('@/components/global/sidebar/SidebarItem.vue'),
    candidatesSidebarFilters: () => import('./filters/sidebar/CandidatesSidebarFilters.vue'),
    quickFilters: () => import('./filters/CandidatesQuickFilters.vue')
  }
}
</script>

<style lang="scss" scoped>
.filters-wrapper {
  z-index: 1;
  position: fixed;
  right: 0;
  top: 112px;
  padding-left: 0;
  padding-right: 0;
  padding-top: 12px;
  width: 0;
  background: $default-bg-color;
  height: 100vh;

  &_active {
    padding-left: 14px;
    padding-right: 14px;
    width: 30%;

    @media #{$mobile} {
      width: 80%;
    }
  }

  &::v-deep {
    .v-expansion-panel-content__wrap {
      padding: 14px;
    }
  }
}

.filters-candidate {
  padding-top: 14px;
  padding-bottom: 14px;
  border-bottom: 1px solid $border-color;
  display: flex;
  align-items: center;

  &__main {
    margin-right: 20px;
    display: flex;
  }

  .search {
    width: 100%;
  }
}

.search::v-deep {
  &.v-input .v-text-field__slot input {
    padding: 8px 0 8px;
  }
}

.c-checkbox {
  margin-top: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
}

.candidates-labels {
  display: flex;
}

@media #{$mobile} {
  .filters-candidate {
    flex-wrap: wrap;
  }
  .candidates-labels {
    width: 100%;
    justify-content: space-between;
  }
}

.candidates-labels-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
</style>

import PaginationDTO from "@/DTO/limitOffsetDTO"

export default class PaginationModel {
  constructor(item) {
    Object.assign(this, new PaginationDTO(item))
  }

  loadMore() {
    this.offset += this.limit;
  }
}
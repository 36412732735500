export default class CandidatesCount {
  constructor() {
    this.reservedExchanged = '...';
    this.reserveExchange = '...';
    this.will_come_candidates_count = '...';
    this.canceled_list = '...';
    this.will_not_come_candidates_count = '...';
    this.tasks = '...';
    this.wait = '...';
    this.needAuthorization = '...';
    this.processed = '...';
    this.mijnCbrQueue = '...';
    this.reservedAll = '...';
    this.topCbrQueue = '...';
    this.all = '...';
    this.topСbrExchangeQueue = '...';
    this.topСbrReserveQueue = '...';
    this.allWillCome = '...';
    this.allWillNotCome = '...';
  }

  set(item = {}) {
    this.reservedExchanged = item.reserved_and_exchanged;
    this.reserveExchange = item.reserve_and_exchange_queue;
    this.tasks = item.task_list;
    this.wait = item.wait_list;
    this.needAuthorization = item.need_authorization;
    this.processed = item.processed;
    this.mijnCbrQueue = item.mijn_cbr_queue;
    this.reservedAll = item.all_reserved;
    this.topCbrQueue = item.top_cbr_queue;
    this.all = item.all;
    this.topСbrReserveQueue = item.top_cbr_reserve_queue;
    this.topСbrExchangeQueue = item.top_cbr_exchange_queue;
    this.allWillCome = item.all_will_come;
    this.allWillNotCome = item.all_will_not_come;
    this.canceled_list = item.canceled_list;
    this.will_not_come_candidates_count = item.will_not_come_candidates_count;
    this.will_come_candidates_count = item.will_come_candidates_count;
  }
}

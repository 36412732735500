import { mapGetters } from 'vuex';
import { CRM_PERMS } from '@/util/const';

export default {
  inject: ['perms'],

  computed: {
    ...mapGetters({
      currentUser: 'user/current'
    }),

    permissions() {
      return this.perms.avaliablePerms;
    },

    isSuperUser() {
      return !!this.currentUser.crmSuperUser;
    },

    // permissions
    hasMergeCandidatesPermissions() {
      if (!this.permissions) return false;
      return !!this.permissions.includes(CRM_PERMS.MERGE_CANDIDATES);
    },

    hasCourseLeadersPermissions() {
      if (!this.permissions) return false;
      return !!this.permissions.includes(CRM_PERMS.COURSES_LEADERS);
    },

    hasCoursesPermissions() {
      if (!this.permissions) return false;
      return !!this.permissions.includes(CRM_PERMS.COURSES);
    },

    hasReapplyPermissions() {
      if (!this.permissions) return false;
      return !!this.permissions.includes(CRM_PERMS.ADD_REAPPLY);
    },

    hasEditOnlinePermissions() {
      if (!this.permissions) return false;
      return !!this.permissions.includes(CRM_PERMS.EDIT_ONLINE);
    },

    hasAddPayoutInDrivingSchoolPermission() {
      if (!this.permissions) return false;
      return !!this.permissions.includes(CRM_PERMS.DRIVING_SCHOOLS_CAN_ADD_PAYOUT);
    },
    //

    isSuperVisor() {
      return !!(this.isSuperUser || this.hasMergeCandidatesPermissions);
    },

    isCourseLeadersAccess() {
      return this.hasCourseLeadersPermissions && !this.hasCoursesPermissions;
    },

    isCoursesAccess() {
      return !!(this.isSuperUser || this.hasCoursesPermissions);
    },

    canManageOnlineAccess() {
      return !!(this.isSuperUser || this.hasEditOnlinePermissions);
    },

    hasReapplyAccess() {
      return !!(this.isSuperUser || this.hasReapplyPermissions);
    },

    canAddPayoutInDrivingSchool() {
      return !!(this.isSuperUser || this.hasAddPayoutInDrivingSchoolPermission);
    }
  }
};

import { SelectBase } from "@/util/selector"

export const PAYMENT_ARG = {
  LABEL: 'label',
  VALUE: 'amount',
  SUBJECT: 'subject',
  CONTENT: 'content',
  NOTES: 'notes'
}

export const PAYMENT_LABEL = {
  CUSTOM: 'custom'
}

export const PAYMENT_STATUS = {
  OPEN: 'open',
  PAID: 'paid',
  CANCELED: 'canceled'
}

export const PAID_MANUALLY = 'PAID_MANUALLY'

export class PaymentModel {
  constructor(item = {}) {
    this.label = item[PAYMENT_ARG.LABEL] || ''
    this.value = item[PAYMENT_ARG.VALUE]
    this.notes = item.notes
  }
}

export class PaymentSystem extends PaymentModel {
  constructor(item = {}) {
    super(item)
    this.systemPayment = true
  }
}

export class PaymentSelector extends SelectBase {
  constructor(payment) {
    super(payment)
    this.payment = payment
  }

  get label() {
    return this.payment.label
  }

  get value() {
    return this.payment.value
  }

  get systemPayment() {
    return this.payment.systemPayment
  }

  get notes() {
    return this.payment.notes
  }

  set notes(val) {
    this.payment.notes = val
  }
}

export class PaymentCustom extends PaymentModel{
  constructor(item = {}) {
    super(item)
    if(item.value) this.value = item.value
    if(item.label) this.label = item.label
    this.content = item.content || ''
    this.candidate = ''
    this.subject = item.subject || ''
  }
}

export class PaymentLog {
  constructor(item = {}) {
    this.ID = item.id
    this.label = item.label
    this.status = item.status
    this.isArchived = item.is_archived
    this.amount = item.amount
    this.paidAt = item.paid_at
    this.notes = item.notes
    this.createdAt = item.created_at
    this.refunds = item.refunds && item.refunds.length ? item.refunds.map(refund => new PaymentLog(refund)): []
  }
}
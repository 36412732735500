<template lang="pug">
  .page-container.candidates-page
    portal(to="toolbar-title")
      toolbar-title Candidates
        span(v-if="getTitleLocation()")  | {{ courseLocations | normalizeListWithComma }}

    .page-container__block.border-b
      tabs(@change="changeTabs" :counts="candidatesCount")

    .page-container__block.transparent.mb-4.ml-3.mr-3
      portal-target(name="candidates-filters-chips")

    .page-container__block.block
      portal-target(name="candidates-list-top")

    .page-container__block.border-b.quick-f
      portal-target(name="candidates-quick-filters")

    .page-container__block.filters
      portal-target(name="candidates-filters")

    div(data-test="candidates")
      create
      .candidates-page.d-flex
        .full-width
          router-view(ref="list" @candidatesCountsChanged="setCandidatesCount")
          log
            template(v-slot:action="log")
              span
                v-btn(color="primary" fab @click="log.loadLogs" data-test="log-btn" :class="{'filters-hide': showingFilters}").btn-floating
                  v-icon(color="white") mdi-post

                filters(
                  :showing.sync="showingFilters"
                  :candidatesCount="candidatesCount"
                  @update:showing="log.onlyHide"
                  @filterChanged="loadList"
                  @clickedOnLocationTab="goToLocation"
                  @refreshClicked="loadList"
                  data-test="filters"
                  ref="filters"
                )
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import { getMultipleQueryValue } from '@/util'
import {FILTERS_ARGS} from './core/candidates-const'
import CandidatesSvc from '@/app/admin/modules/candidates/core/candidates-service'
import Filters from './components/CandidatesFilters.vue'
import CandidatesCount from './core/models/candidatesCountModel'
import Log from './components/CandidatesLog.vue'
import errorsMixin from '@/mixins/errors.mixin'
import permsMixin from '@/mixins/perms.mixin'
import PaginationMixin from '@/mixins/pagination.mixin'

export default {
  name: 'CandidatesPage',

  data: () => ({
    svc: null,
    logs: null,
    logsShowing: false,
    logActive: true,
    showingFilters: false,
    candidatesCount: new CandidatesCount(),
    loading: false
  }),

  inject: ['perms'],

  mixins: [errorsMixin, PaginationMixin, permsMixin],

  computed: {
    ...mapGetters({
      currentUser: 'user/current',
      cities: 'crmCourseCities/items',
    }),

    courseLocations() {
      let locations = getMultipleQueryValue(this.$route.query[FILTERS_ARGS.CITY_COURSE])
      if (!locations.length || !this.cities) return false
      return locations.map(cityID => {
        let sameCity = this.cities.find(city => +city.ID === +cityID)
        if (!sameCity) return
        return sameCity.name
      })
    }
  },

  created() {
    this.init()
  },

  async mounted() {
    await this.loadLabels()
  },

  methods: {
    ...mapActions({
      loadLabels: 'labels/list'
    }),

    init() {
      this.svc = new CandidatesSvc()
    },

    changeTabs() {
      this.loadList()
      this.$refs.filters.resetFilters()
    },

    async goToLocation(query) {
      let needToUpdateList = false
      if (this.$route.name === this.$ROUTER_NAMES.CANDIDATES) needToUpdateList = true
      this.$router.push({name: this.$ROUTER_NAMES.CANDIDATES, query: query})
      if (needToUpdateList) this.loadList()
    },

    loadList() {
      this.$refs.list.loadList({})
    },

    getTitleLocation() {
      let locations = getMultipleQueryValue(this.$route.query[FILTERS_ARGS.CITY_COURSE])
      if (!locations.length) return false
      return locations.reduce((acc, city) => acc + ', ' + city)
    },

    setCandidatesCount(counts) {
      this.candidatesCount.set(counts)
    }
  },

  provide: function () {
    return {
      getDefaultPagination: this.getDefaultPagination,
      getDefaultPageSize: this.getDefaultPageSize,
      svc: () => this.svc
    }
  },

  components: {
    toolbarTitle: () => import('@/components/global/toolbar/ToolbarTitle.vue'),
    tabs: () => import('./CandidatesTabs.vue'),
    create: () => import('./components/candidates/CandidateCreate.vue'),
    log: Log,
    filters: Filters
  }
}
</script>

<style lang="scss" scoped>
.btn-floating {
  transition: all .3s ease;
  transform: scale(1)
}

.filters-hide {
  transform: scale(0)
}

.reservation {
  margin-top: 15px;
  border: 1px solid rgba(0, 0, 0, 0.24);
  padding: 0 15px;
  border-radius: 6px;
}

.quick-f {
  @include media('>tablet') {
    justify-content: flex-end;
  }
}

.filters {
  padding: 10px;

  @include media("<=tablet") {
    overflow-y: scroll;
    flex-wrap: nowrap;
    width: 100%;
  }
}
</style>

<style lang="scss">
.candidates-page .v-data-table__wrapper table {
  @include media(">tablet") {
    min-width: 2000px;
  }
}
</style>

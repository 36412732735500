import WsInternal from "@/services/api/util/websocket-internal"
import LogItemModel from '../DTO/candidateLogDTO'
import Api from "@/services/api/api-activityLogs"

export default class CandidatesLogModel extends WsInternal {
  constructor(params = {}, wsURL) {
    super(process.env.VUE_APP_CRM_WS + `${wsURL}`)
    this.items = []
    this.loading = false
    this.params = params
  }

  startListen(callback) {
    this.ws.onmessage = event => {
      const wsMessageData = this.getMsgData(event)
      if (!wsMessageData) return
      if (!wsMessageData.page) return
      if (!this.params.page.some(page => page === wsMessageData.page)) return
      this._addToItems(wsMessageData)
      if (callback) callback(wsMessageData)
    }
  }

  listenCandidates(event) {
    const wsMessageData = this.getMsgData(event)
    if (!wsMessageData) return
    if (!wsMessageData.page) return
    if (!this.params.page.some(page => page === wsMessageData.page)) return
    this._addToItems(wsMessageData)
    return wsMessageData
  }

  startListenCandidate(callback) {
    this.ws.addEventListener("message", (event) => {
      this.addToCandidate(event, callback);
    });
  }

  addToCandidate(event, callback) {
    const wsMessageData = this.getMsgData(event);
    if (!wsMessageData) return;
    if (!wsMessageData.page) return;
    if(!('page' in this.params))  {
      let {candidate} = wsMessageData;
      if (candidate && candidate.id !== +this.params.candidate) return;
      this._addToItems(wsMessageData)
      callback(wsMessageData)
      return
    }
    if (!this.params.page.some(page => page === wsMessageData.page)) return;
    let {candidate} = wsMessageData;
    if (candidate && candidate.id !== +this.params.candidate) return;
    this._addToItems(wsMessageData);
    callback(wsMessageData);
  }

  _addToItems(msg) {
    if (this.params && this.params.status && (this.params.status !== msg.status)) return;
    this.items.unshift(new LogItemModel(msg));
  }

  async list(params, more) {
    try {
      params = {...params};
      let res = await Api.list(this._getParams(params));
      let items = res.results.map(item => new LogItemModel(item));
      if (more) this.items.push(...items);
      else this.items = items;
      return res;
    } catch (error) {
      console.log(error);
      this.loading = false;
      return Promise.reject(error);
    }
  }

  _getParams(params) {
    let _params = params;
    if (this.params) _params = {...params, ...this.params};
    return _params;
  }

  removeMessageListener(callback) {
    this.ws.removeEventListener('message', null);
  }
}

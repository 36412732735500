import { DEFAULT_LIMIT_PAGINATION } from '@/util/const';

export default class LimitOffsetDTO {
  constructor(item = {}) {
    this.offset = DEFAULT_LIMIT_PAGINATION.OFFSET;
    this.limit = DEFAULT_LIMIT_PAGINATION.LIMIT;
    this.count = item.count;
    this.next = item.next;
    this.previous = item.previous;
  }
}
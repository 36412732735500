import auth from '@/services/CRMinitAuth';

const list = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}activitylogs/`, {
    params: query
  });
};

const find = ID => {
  return auth.get(`${process.env.VUE_APP_CRM_API}activitylogs/${ID}`);
};

export default {
  list,
  find
};

import { FILTERS_ARGS } from "../candidates-const";
import { getMultipleQueryValueWithNumber, getMultipleQueryValue, transformQueryBoolean } from "@/util";

export default class CandidatesFilterDTO {
  constructor(queries = {}) {
    this.id = queries[FILTERS_ARGS.ID];
    this.city = getMultipleQueryValue(queries[FILTERS_ARGS.CITY]);
    this.courseCity = getMultipleQueryValueWithNumber(queries[FILTERS_ARGS.CITY_COURSE]);
    this.examProduct = getMultipleQueryValue(queries[FILTERS_ARGS.EXAM_PRODUCT]);
    this.licenseType = getMultipleQueryValue(queries[FILTERS_ARGS.LICENSE_TYPE]);
    this.search = queries[FILTERS_ARGS.SEARCH];
    this.cbrUser = getMultipleQueryValueWithNumber(queries[FILTERS_ARGS.CBR_USER]);
    this.cbrStatus = getMultipleQueryValue(queries[FILTERS_ARGS.CBR_STATUS]);
    this.failedExact = transformQueryBoolean(queries[FILTERS_ARGS.FAILED_EXACT]);
    this.queueExamDateAfter = queries[FILTERS_ARGS.QUEUE_EXAM_DATE_AFTER];
    this.queueExamDateBefore = queries[FILTERS_ARGS.QUEUE_EXAM_DATE_BEFORE];
    this.queueExamDateExcludeAfter = queries[FILTERS_ARGS.QUEUE_EXAM_DATE_EXCLUDE_AFTER];
    this.queueExamDateExlucdeBefore = queries[FILTERS_ARGS.QUEUE_EXAM_DATE_EXCLUDE_BEFORE];
    this.examDateBefore = queries[FILTERS_ARGS.EXAM_DATE_BEFORE];
    this.examDateAfter = queries[FILTERS_ARGS.EXAM_DATE_AFTER];
    this.courseDateBefore = queries[FILTERS_ARGS.COURSE_DATE_BEFORE];
    this.courseDateAfter = queries[FILTERS_ARGS.COURSE_DATE_AFTER];
    this.examTimeBefore = queries[FILTERS_ARGS.EXAM_TIME_BEFORE];
    this.examTimeAfter = queries[FILTERS_ARGS.EXAM_TIME_AFTER];
    this.labels = getMultipleQueryValue(queries[FILTERS_ARGS.LABEL]);
    this.candidatePage = queries[FILTERS_ARGS.CANDIDATE_PAGE];
    this.queueExamLocation = getMultipleQueryValue(queries[FILTERS_ARGS.QUEUE_EXAM_LOCATION]);
    this.hasAvaliableSlots = transformQueryBoolean(queries[FILTERS_ARGS.HAS_AVALIABLE_SLOTS]);
    this.examRequestsCount = queries[FILTERS_ARGS.EXAM_REQUESTS_COUNT];
    this.queueExamDateStrict = queries[FILTERS_ARGS.QUEUE_EXAM_DATE_STRICT];
    this.queueTimeAfter = queries[FILTERS_ARGS.QUEUE_TIME_AFTER];
    this.queueTimeBefore = queries[FILTERS_ARGS.QUEUE_TIME_BEFORE];
    this.lastError = queries[FILTERS_ARGS.LAST_ERROR];
    this.isCoursePaid = transformQueryBoolean(queries[FILTERS_ARGS.IS_COURSE_PAID]);
    this.isNotPaidOnline = transformQueryBoolean(queries[FILTERS_ARGS.IS_NOT_PAID_ONLINE]);
    this.courseType = queries[FILTERS_ARGS.COURSE_TYPE];
    this.fixedDates = transformQueryBoolean(queries[FILTERS_ARGS.FIXED_DATES]);
  }
}

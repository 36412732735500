export default class WebSocketInternal {
  constructor(WS_URL) {
    this.ws = new WebSocket(WS_URL)
  }

  async event(callback) {
    this.ws.onmessage = event => {
      const wsMessageData = JSON.parse(event.data);
      if(!wsMessageData) return
      callback(wsMessageData)
    }
  }

  getMsgData(event) {
    return JSON.parse(event.data)
  }

  close() {
    this.ws.close()
  }
}
<template lang="pug">
span
  slot(:loadLogs="loadLogs" 
    :updateLogsShowing="updateLogsShowing" 
    :onlyHide="onlyHide"
    name="action"
  )
  v-navigation-drawer(
    :value="logsShowing"
    @input="updateLogsShowing"
    fixed
    right
    touchless
    width="400"
    ref='drawer'
  )
    logs(:value="logsShowing" @click:loadMore="loadLogsMore" :model="logs" ref="log")
      template(v-slot:header="props")
        logsTabs(:model="props.model" :loadData="props.loadData" :key="logsShowing" :getDefaultPaginationParams="props.getDefaultPaginationParams")
      template(v-slot:candidateName="{candidate, fullName}")
        span.link  {{ fullName }}
        span.link  -
          span(v-html="spliceOnTwoElement(candidate.number)") 

</template>

<script>
import CandidatesLogModel from '../core/models/candidatesLogModel'
import { LOG_PAGE } from '../../../../../util/const'
import CRMauth from "@/services/CRMinitAuth"
import errorsMixin from '@/mixins/errors.mixin'
import Logs from "@/app/admin/components/Log.vue"
import {mapGetters} from "vuex"

export default {
  name: 'CandidatesLog',

  mixins: [errorsMixin],

  data: () => ({
    logs: {},
    logsShowing: false,
  }),

  computed: {
    ...mapGetters({
      currentUser: 'user/current'
    })
  },
  
  methods: {
    async initLogs(wsURL) {
      try {
        let tokens
        tokens = await CRMauth.getTokensFromRepoOrRefresh()
        this.logs = new CandidatesLogModel(
          {page: [LOG_PAGE.ACCEPTANCE, LOG_PAGE.RESERVATIONS, LOG_PAGE.CONTANCT]}, 
          `${wsURL}?auth_token=${tokens.access}`
        )
        
        this.logs.ws.addEventListener("message", this.listenCandidates)
      } catch (error) {
        this.processError(error, {
          redirectName: this.$ROUTER_NAMES.LOGIN_CRM
        })
      }
      
    },

    listenCandidates(msg) {
      this.logs.listenCandidates(msg)
    },

    async loadLogs() {
      if(this.logsShowing) {
        this.updateLogsShowing(false)
        return
      }
      await this.initLogs(
        `${this.currentUser.logsWebsocketPath}`
      )
      this.logsShowing = true
      this.$refs.log.loadData({
        ...this.$refs.log.getDefaultPaginationParams(), 
          
      })   
    },

    closeWS() {
      this.logs.ws.removeEventListener("message", this.listenCandidates)
      this.logs.ws.close()
    },

    loadLogsMore() {
      this.$refs.log.loadMore({
        ...this.$refs.log.getPaginationParams(),
      })
    },

    updateLogsShowing(val) {
      this.logsShowing = val
      if(!val) this.closeWS()
    },

    onlyHide() {
      this.logsShowing = false
    },
    
    spliceOnTwoElement(number) {
      number = number + ''
      let numberArr = number.split('')
      let halfLength = Math.ceil(numberArr.length / 2);  
      let leftSide = numberArr.splice(0, halfLength);
      let html = `<span>${leftSide.join('')}</span><span>${numberArr.join('')}</span> `
      return html
    }
  },

  components: {
    logs: Logs,
    logsTabs: () => import ('./candidates/CandidatesLogTabs.vue'),
  }
}
</script>

<style lang="scss" scoped>
.btn-floating {
  transition: all .3s ease;
  transform: scale(1)
}

</style>
import FiltersDTO from '../DTO/candidates-filterDTO';
import {
  FILTERS_ARGS,
  CBR_STATUS,
  CBR_STATUS_OPTIONS,
  QUEUE_OPTIONS,
  FAILED_OPTIONS,
  ON_HOLD_OPTIONS,
  DEFAULT_BOOLEAN_OPTIONS,
  CANDIDATE_PAGE, LICENSE_TYPE_OPTIONS
} from '../candidates-const';
import { getMultipleQueryValue, getMultipleQueryValueWithNumber } from '@/util';

export default class CandidatesFiltersModel {
  constructor(filters = {}) {
    this.filters = new FiltersDTO(filters);
    this.labels = getMultipleQueryValue(filters[FILTERS_ARGS.LABEL]);
    this.labelsExclude = getMultipleQueryValue(filters[FILTERS_ARGS.LABEL_EXCLUDE]);
    this.customLabels = getMultipleQueryValueWithNumber(filters[FILTERS_ARGS.CUSTOM_LABELS]);
    this.customLabelsExclude = getMultipleQueryValueWithNumber(filters[FILTERS_ARGS.CUSTOM_LABELS_EXCLUDE]);
    this.options = {
      cities: {loading: false, list: []},
      courseCities: {loading: false, list: []},
      queueExamLocation: {loading: false, list: []},
      cbrUsers: {loading: false, list: []},
      cbrStatus: {loading: false, list: CBR_STATUS_OPTIONS},
      examProducts: {loading: false, list: []},
      licenseTypes: {loading: false, list: LICENSE_TYPE_OPTIONS},
      queue: {loading: false, list: FAILED_OPTIONS},
      failed: {loading: false, list: ON_HOLD_OPTIONS},
      onHold: {loading: false, list: QUEUE_OPTIONS},
      inExchangeQueue: {list: DEFAULT_BOOLEAN_OPTIONS},
      wasExchanged: {list: DEFAULT_BOOLEAN_OPTIONS},
      fixedDates: {list: DEFAULT_BOOLEAN_OPTIONS}
    };
  }

  async loadFilterOptions(store) {
    await this.setExamCities(store);
    await this.setCBRUsers(store);
    await this.setCourseCities(store);
    await this.setProductNames(store);
  }

  getApiData() {
    let apiData = {
      [FILTERS_ARGS.ID]: this.filters.id,
      [FILTERS_ARGS.SEARCH]: this.filters.search && this.filters.search.length ? this.filters.search : undefined,
      [FILTERS_ARGS.CITY]: this.filters.city,
      [FILTERS_ARGS.CITY_COURSE]: this.filters.courseCity,
      [FILTERS_ARGS.QUEUE_EXAM_LOCATION]: this.filters.queueExamLocation,
      [FILTERS_ARGS.CBR_USER]: this.filters.cbrUser,
      [FILTERS_ARGS.EXAM_PRODUCT]: this.filters.examProduct,
      [FILTERS_ARGS.LICENSE_TYPE]: this.filters.licenseType,
      [FILTERS_ARGS.CBR_STATUS]: this.filters.cbrStatus,
      [FILTERS_ARGS.QUEUE_EXAM_DATE_BEFORE]: this.filters.queueExamDateBefore,
      [FILTERS_ARGS.QUEUE_EXAM_DATE_AFTER]: this.filters.queueExamDateAfter,
      [FILTERS_ARGS.EXAM_DATE_BEFORE]: this.filters.examDateBefore,
      [FILTERS_ARGS.EXAM_DATE_AFTER]: this.filters.examDateAfter,
      [FILTERS_ARGS.COURSE_DATE_BEFORE]: this.filters.courseDateBefore,
      [FILTERS_ARGS.COURSE_DATE_AFTER]: this.filters.courseDateAfter,
      [FILTERS_ARGS.EXAM_TIME_AFTER]: this.filters.examTimeAfter,
      [FILTERS_ARGS.EXAM_TIME_BEFORE]: this.filters.examTimeBefore,
      [FILTERS_ARGS.LABEL]: this.labels,
      [FILTERS_ARGS.LABEL_EXCLUDE]: this.labelsExclude,
      [FILTERS_ARGS.CUSTOM_LABELS]: this.customLabels,
      [FILTERS_ARGS.CUSTOM_LABELS_EXCLUDE]: this.customLabelsExclude,
      [FILTERS_ARGS.CANDIDATE_PAGE]: this.filters.candidatePage,
      [FILTERS_ARGS.HAS_AVALIABLE_SLOTS]: this.filters.hasAvaliableSlots ? true : undefined,
      [FILTERS_ARGS.EXAM_REQUESTS_COUNT]: this.filters.examRequestsCount,
      [FILTERS_ARGS.QUEUE_TIME_AFTER]: this.filters.queueTimeAfter,
      [FILTERS_ARGS.QUEUE_TIME_BEFORE]: this.filters.queueTimeBefore,
      [FILTERS_ARGS.LAST_ERROR]: this.filters.lastError,
      [FILTERS_ARGS.IS_COURSE_PAID]: this.filters.isCoursePaid,
      [FILTERS_ARGS.IS_NOT_PAID_ONLINE]: this.filters.isNotPaidOnline,
      [FILTERS_ARGS.QUEUE_EXAM_DATE_STRICT]: this.filters.queueExamDateStrict,
      [FILTERS_ARGS.COURSE_TYPE]: this.filters.courseType,
      [FILTERS_ARGS.FIXED_DATES]: this.filters.fixedDates,
    }
    return {
      ...apiData,
      ...this.getQueueExamDateApiData()
    };
  }

  getQueueExamDateApiData() {
    let apiData = {}
    if (this.filters.queueExamDateStrict) {
      apiData = {
        [FILTERS_ARGS.QUEUE_EXAM_DATE_BEFORE]: this.filters.queueExamDateBefore,
        [FILTERS_ARGS.QUEUE_EXAM_DATE_AFTER]: this.filters.queueExamDateAfter,
        [FILTERS_ARGS.QUEUE_EXAM_DATE_EXCLUDE_AFTER]: this.filters.queueExamDateBefore,
        [FILTERS_ARGS.QUEUE_EXAM_DATE_EXCLUDE_BEFORE]: this.filters.queueExamDateAfter,
        [FILTERS_ARGS.QUEUE_EXAM_DATE_STRICT]: this.filters.queueExamDateStrict
      }
      return apiData
    }
    apiData = {
      [FILTERS_ARGS.QUEUE_EXAM_DATE_BEFORE]: this.filters.queueExamDateBefore,
      [FILTERS_ARGS.QUEUE_EXAM_DATE_AFTER]: this.filters.queueExamDateAfter,
      [FILTERS_ARGS.QUEUE_EXAM_DATE_EXCLUDE_AFTER]: undefined,
      [FILTERS_ARGS.QUEUE_EXAM_DATE_EXCLUDE_BEFORE]: undefined,
      [FILTERS_ARGS.QUEUE_EXAM_DATE_STRICT]: this.filters.queueExamDateStrict
    }
    return apiData
  }

  loadAll() {
    let courseCity = this.filters.courseCity
    this.reset()
    this.filters.courseCity = courseCity
    this.filters.candidatePage = CANDIDATE_PAGE.ALL
    return this.getApiData()
  }

  async setExamCities(store) {
    this.options.cities.loading = true
    this.options.queueExamLocation.loading = true
    let cities = await store.dispatch(`crmExamLocation/list`, {cache: true})
    this.options.cities.list = cities
    this.options.cities.loading = false
    this.options.queueExamLocation.list = cities
    this.options.queueExamLocation.loading = false
  }

  async setCourseCities(store) {
    this.options.courseCities.loading = true
    let cities = await store.dispatch(`crmCourseCities/list`, {cache: true})
    this.options.courseCities.list = cities
    this.options.courseCities.loading = false
  }

  async setCBRUsers(store) {
    this.options.cbrUsers.loading = true
    let users = await store.dispatch(`cbrUser/list`, {cache: true})
    this.options.cbrUsers.list = users
    this.options.cbrUsers.loading = false
  }

  async setProductNames(store) {
    this.options.examProducts.loading = true
    let users = await store.dispatch(`crmProductNames/list`, {cache: true})
    this.options.examProducts.list = users
    this.options.examProducts.loading = false
  }

  reset() {
    this.filters.id = undefined
    this.filters.city = []
    this.filters.courseCity = []
    this.filters.examProduct = []
    this.filters.licenseType = []
    this.filters.cbrUser = []
    this.filters.inQueue = undefined
    this.filters.failed = undefined
    this.filters.onHold = undefined
    this.filters.cbrStatus = []
    this.filters.failedExact = undefined
    this.filters.inExchangeQueue = undefined
    this.filters.wasExchanged = undefined
    this.filters.candidatePage = undefined
    this.filters.queueExamDateAfter = undefined
    this.filters.queueExamDateBefore = undefined
    this.filters.examDateAfter = undefined
    this.filters.examDateBefore = undefined
    this.filters.courseDateAfter = undefined
    this.filters.courseDateBefore = undefined
    this.filters.examTimeAfter = undefined
    this.filters.examTimeBefore = undefined
    this.filters.queueExamLocation = []
    this.filters.hasAvaliableSlots = undefined
    this.filters.examRequestsCount = undefined
    this.filters.queueTimeAfter = undefined
    this.filters.queueTimeBefore = undefined
    this.filters.lastError = undefined
    this.filters.queueExamDateStrict = undefined
    this.filters.isCoursePaid = undefined
    this.filters.isNotPaidOnline = undefined
    this.filters.courseType = undefined
    this.filters.fixedDates = undefined
    this.labels = []
    this.customLabels = []
    this.labelsExclude = []
    this.customLabelsExclude = []
  }

  getTabIndex() {
    let tabIndex = 0
    switch (this.filters.candidatePage) {
    case CANDIDATE_PAGE.TASKS:
      tabIndex = 0
      break;
    case CANDIDATE_PAGE.WAIT:
      tabIndex = 1
      break;
    case CANDIDATE_PAGE.RESERVE_EXCHANGE:
      tabIndex = 2
      break;
    case CANDIDATE_PAGE.RESERVED_EXCHANGED:
      tabIndex = 3
      break;
    case CANDIDATE_PAGE.PROCESSED:
      tabIndex = 4
      break;
    case CANDIDATE_PAGE.ALL:
      tabIndex = 5
      break;
    default:
      break;
    }
    return tabIndex
  }
}
